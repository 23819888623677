
export default {
  data () {
    return {
      // 下面数据明细-表头
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('orderDate', '订单日期').setSpan({}).setWidth(120),
        BaseTableColumn.initData('propertyCompanyName', '物业公司名称', 140).setSpan({}),
        BaseTableColumn.initData('communityName', '小区名称', 120).setSpan({}),
        BaseTableColumn.initData('communityCode', '小区编码', 100).setSpan({ canCopy: true }),
        BaseTableColumn.initData('chargeOrderNo', '充电订单编码', 100).setSpan({ canCopy: true }),
        BaseTableColumn.initData('power', '结算功率', 70).setSpan({ canTextUnit: 'w' }),
        BaseTableColumn.initData('road', '用电量', 70).setSpan({ canTextUnit: 'du' }),
        // BaseTableColumn.initData('energyCosts', '能耗成本', 70).setSpan({}),
        BaseTableColumn.initData('orderRealReceived', '订单实收\n金额', 80).setSpan({ canPrice: true }),
        BaseTableColumn.initData('area', '服务费支出').setKeyValueList([
          { label: '支付渠道:', labelWidth: 70, key: 'payChannelServiceDisburse', attr: { canPrice: true } },
          { label: '系统服务:', labelWidth: 70, key: 'sysChannelServiceDisburse', attr: { canPrice: true } }
        ]).setWidth(160),
        // BaseTableColumn.initData('giftAmount', '扣除活动金额', 90).setSpan({ className: 'color-text-strong', canPrice: true }), // 赠送赠送
        BaseTableColumn.initData('canSubAccount', '可分账金额', 90).setSpan({ className: 'color-text-strong', canPrice: true }),
        BaseTableColumn.initData('area', '分账金额').setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'serviceProviderSubAccount', attr: { canPrice: true } },
          { label: '代理商:', labelWidth: 70, key: 'agentSubAccount', attr: { canPrice: true } },
          { label: '物业公司:', labelWidth: 70, key: 'propertyCompanySubAccount', attr: { canPrice: true } }
        ]).setWidth(160),
        BaseTableColumn.initData('sourceDesc', '数据来源').setSpan({})
      ]
    };
  }
};

import BaseModel from '@/libs/base/dataModel/BaseModel';

export class CapitalOrderTotalModel extends BaseModel {
  constructor () {
    super();
    this.singlePayNum = ''; // 单次付费订单笔数
    this.singlePayTotalAccount = ''; // 单次付费订单支付总金额
    this.singleRefundTotalAccount = ''; // 单次付费订单退款总金额
    this.singlePayFundsReceived = ''; // 单次付费订单实收总金额
    this.payChannelServiceDisburse = ''; // 支付渠道服务费支出
    this.sysChannelServiceDisburse = ''; // 系统服务费支出
    this.serviceProviderSubAccount = ''; // 服务商分账金额
    this.agentSubAccount = ''; // 代理商分账金额
    this.propertyCompanySubAccount = ''; // 物业公司分账金额
    this.giftAmount = ''; // 扣除活动金额 赠送赠送
    this.chargeCoinsPMSubAccount = ''; // 物业公司充电币分账金额
    this.chargeCoinsAgentSubAccount = ''; // 代理商充电币能够分账金额
    this.chargeCoinsSPSubAccount = ''; // 服务商充电币分账金额
    this.chargeCoinsCanAllocateMoney = ''; // 充电币能够分账金额
    this.chargeOrderCanAllocateMoney = ''; // 充电订单能够分账金额
    this.returnAllocateNum = ''; // 扣回分账订单数
    this.returnAllocateMoney = ''; // 扣回分账总金额
    this.returnSpAllocateMoney = ''; // 退回服务商分账金额
    this.returnAgentAllocateMoney = ''; // 退回代理商分账金额
    this.returnPmAllocateMoney = ''; // 退回物业公司分账金额
  }

  static createFakeData (len = 30) {
    return {
      singlePayNum: parseFloat((Math.random() * 99999).toFixed(2)),
      singlePayTotalAccount: parseFloat((Math.random() * 99999).toFixed(2)),
      singleRefundTotalAccount: parseFloat((Math.random() * 99999).toFixed(2)),
      singlePayFundsReceived: parseFloat((Math.random() * 99999).toFixed(2)),
      payChannelServiceDisburse: parseFloat((Math.random() * 99999).toFixed(2)),
      sysChannelServiceDisburse: parseFloat((Math.random() * 99999).toFixed(2)),
      serviceProviderSubAccount: parseFloat((Math.random() * 99999).toFixed(2)),
      agentSubAccount: parseFloat((Math.random() * 99999).toFixed(2)),
      propertyCompanySubAccount: parseFloat((Math.random() * 99999).toFixed(2))
    };
  }
}

export class CapitalOrderListModel extends BaseModel {
  constructor () {
    super();
    this.orderDate = ''; // 日期
    this.propertyCompanyName = ''; // 物业公司名称
    this.propertyCompanyId = ''; // 物业公司名称
    this.communityName = ''; // 小区名称
    this.communityCode = ''; // 小区编码
    this.communityId = ''; // 小区Id
    this.singlePayNum = ''; // 单次付费订单笔数
    this.singlePayTotalAccount = ''; // 单次付费订单支付总金额
    this.singleRefundTotalAccount = ''; // 单次付费订单退款总金额
    this.singlePayFundsReceived = ''; // 单次付费订单实收总金额
    this.payChannelServiceDisburse = ''; // 支付渠道服务费支出
    this.sysChannelServiceDisburse = ''; // 系统服务费支出
    this.canSubAccount = ''; // 可分账金额
    this.serviceProviderSubAccount = ''; // 服务商分账金额
    this.agentSubAccount = ''; // 代理商分账金额
    this.propertyCompanySubAccount = ''; // 物业公司分账金额
    this.giftAmount = ''; // 活动金额 赠送赠送
    this.sourceType = ''; // 来源
    this.sourceTypeDesc = ''; // 来源描述
    this.chargeCoinCanSubAccount = ''; // 充电币可分账金额
    this.chargeCoinServiceProviderSubAccount = ''; // 充电币服务商分账金额
    this.chargeCoinAgentSubAccount = ''; // 充电币代理商分账金额
    this.chargeCoinPropertyCompanySubAccount = ''; // 充电币物业公司分账金额
    this.provinceName = ''; // 省
    this.cityName = ''; // 市
    this.districtName = ''; // 区
    this.pcSapCode = ''; // 物业spa项目编码
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        orderDate: '2024-01-01',
        propertyCompanyName: BaseModel.getFakeTitle(10),
        communityName: BaseModel.getFakeTitle(10),
        communityCode: 123,
        singlePayNum: 10,
        singlePayTotalAccount: BaseModel.getFakeNumber(),
        singleRefundTotalAccount: BaseModel.getFakeNumber(),
        singlePayFundsReceived: BaseModel.getFakeNumber(),
        payChannelServiceDisburse: BaseModel.getFakeNumber(),
        sysChannelServiceDisburse: BaseModel.getFakeNumber(),
        serviceProviderSubAccount: BaseModel.getFakeNumber(),
        agentSubAccount: BaseModel.getFakeNumber(),
        canSubAccount: BaseModel.getFakeNumber(),
        propertyCompanySubAccount: BaseModel.getFakeNumber()
      };
      list.push(dic);
    }
    return list;
  }
}

export class CapitalOrderDetailListModel extends BaseModel {
  constructor () {
    super();
    this.orderDate = ''; // 日期
    this.propertyCompanyName = ''; // 物业公司名称
    this.propertyCompanyId = ''; // 物业公司名称
    this.communityName = ''; // 小区名称
    this.communityCode = ''; // 小区编码
    this.chargeOrderNo = ''; // 充电订单编码
    this.power = ''; // 结算功率
    this.road = ''; // 用电量
    this.energyCosts = ''; // 能耗成本
    this.orderRealReceived = ''; // 订单实收金额
    this.payChannelServiceDisburse = ''; // 支付渠道服务费支出
    this.sysChannelServiceDisburse = ''; // 系统服务费支出
    this.canSubAccount = ''; // 可分账金额
    this.serviceProviderSubAccount = ''; // 服务商分账金额
    this.agentSubAccount = ''; // 代理商分账金额
    this.propertyCompanySubAccount = ''; // 物业公司分账金额
    this.giftAmount = ''; // 活动金额 赠送赠送
    this.sourceType = ''; // 来源
    this.sourceTypeDesc = ''; // 来源描述
    this.sourceDesc = ''; // 来源描述
  }

  initData (resData) {
    super.initData(resData);
    this.power = this.power < 0 ? '空载' : this.power;
    return this;
  }
}

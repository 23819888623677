<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称:财务管理-分账订单管理
-->
<style lang="less">
#capital-manager {
  min-width: 700px;
  .card-outer {
    margin: 0 -15px;
    .total-data-box {
      display: flex;
      //flex-wrap: wrap;
      border-radius: @borderRadiusMin;
      overflow: auto;
      border: 1px solid @lineColorNormal;
      border-right: 0;
      .total-data-item {
        flex: 1;
        min-width: 100px;
        border-right: 1px solid @lineColorNormal;
        &:nth-of-type(2n) {
          .total-data-label {
            //background-color: transparent;
          }
        }
        .total-data-label {
          padding: 8px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          white-space: pre-line;
          color: @textColorLight;
          background-color: @backColorNormal;
        }
        .total-data-value {
          .display-flex();
          line-height: 70px;
          font-weight: 600;
          font-size: 13px;
        }
        .sa-price-total-data-value {
          height: 70px;
          padding: 6px 0;
          box-sizing: border-box;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
  .color-text-strong { color: @adornColor; font-weight: 600 }
  .color-text-finish { color: @finishColor; }
  .color-text-primary { color: @textColorNormal; }
  .can-click-style {
    text-decoration: underline;
    cursor: pointer;
    color: @themeColor;
  }
}
</style>

<template>
  <div id="capital-manager">
    <!-- 查询条件 -->
    <div v-permission="'system:finance:detail:query'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div
      v-permission="'system:finance:summary:list'"
      class="card-outer"
    >
      <yg-card
        title="数据汇总"
      >
        <div slot="content">
          <div class="total-data-box">
            <div
              v-for="(item, i) in totalItems"
              :key="i"
              class="total-data-item"
              :style="'min-width: ' + item.itemWidth + 'px'"
            >
              <div class="total-data-label">
                {{ item.label }}
              </div>
              <div
                v-if="item.isKeyValue"
                class="sa-price-total-data-value"
              >
                <yg-attribute-key-value
                  v-for="(re, ind) in item.keyValueData"
                  :key="ind"
                  :label="re.label"
                  :label-width="80"
                  :value="pageData[re.value] || '-'"
                  :can-price="re.isPrice"
                />
              </div>
              <yg-attribute-span
                v-else
                :can-price="item.priceText"
                :text="pageData[item.key] || '-' "
                class="total-data-value"
                :class="`${'color-text-' + item.textColor} ${item.canClick && pageData[item.key] ? 'can-click-style' : ''}`"
                @click.native="item.canClick && pageData[item.key] ? _clickValue(item.key) : ''"
              />
            </div>
          </div>
        </div>
      </yg-card>
    </div>
    <div
      v-permission="'system:finance:detail:query'"
      class="card-outer"
      style="margin-top: -5px"
    >
      <yg-card
        title="数据明细"
        style="padding-bottom: 0"
      >
        <div slot="content">
          <div class="margin-primary-bottom">
            <yg-setting-area
              :items="settingItems"
              :total="page.total"
              :export-condition="settingExportCondition"
              @on-click="_clickSetting"
            />
          </div>
          <yg-table
            :columns="tableColumns"
            :data="tableData"
            :max-height="9999"
            :page="page"
            @on-page-change="onChangePage"
          />
        </div>
      </yg-card>
    </div>
    <!--弹窗:详情-->
    <v-detail
      v-if="!!dialog.detailModel"
      :model="dialog.detailModel"
      @on-close="dialog.detailModel = null"
    />
    <!--弹窗:扣回分账管理-->
    <v-deduct-back
      v-if="!!dialog.deductBackModel"
      :model="dialog.deductBackModel"
      :search-options-data="dialog.deductBackSearchOptionsData"
      @on-close="dialog.deductBackModel = null"
    />
  </div>
</template>

<script>
import { CapitalOrderTotalModel, CapitalOrderListModel } from './model/CapitalOrderDataModel';
import FinanceApiSet from '@/request/api/FinanceApiSet';
import ColumnMixin from './mixin/tableColumn';
import vDetail from './view/detail.vue';
import vDeductBack from './view/deductBack.vue';
export default {
  components: { vDetail, vDeductBack },
  mixins: [ColumnMixin],
  data () {
    return {
      totalItems: [
        { key: 'singlePayNum', label: '订单\n笔数', textColor: 'strong', priceText: false, isKeyValue: false },
        { key: 'singlePayTotalAccount', label: '订单支付\n总金额', textColor: 'strong', priceText: true, isKeyValue: false },
        { key: 'singleRefundTotalAccount', label: '退款\n总金额', textColor: 'strong', priceText: true, isKeyValue: false },
        { key: 'singlePayFundsReceived', label: '实收\n总金额', textColor: 'strong', priceText: true, isKeyValue: false },
        { key: 'payChannelServiceDisburse', label: '支付渠道服务费\n支出', textColor: 'finish', priceText: true, isKeyValue: false },
        { key: 'sysChannelServiceDisburse', label: '系统服务费\n支出', textColor: 'finish', priceText: true, isKeyValue: false },
        // { key: 'giftAmount', label: '扣除\n活动金额', textColor: 'primary', priceText: true, isKeyValue: false }, // 赠送赠送
        {
          key: '',
          label: '可分账\n金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'chargeOrderCanAllocateMoney', isPrice: true },
            { label: '充电币:', value: 'chargeCoinsCanAllocateMoney' }
          ]
        },
        {
          key: '',
          label: '服务商\n分账金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'serviceProviderSubAccount', isPrice: true },
            { label: '充电币:', value: 'chargeCoinsSPSubAccount' }
          ]
        },
        {
          key: '',
          label: '代理商\n分账金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'agentSubAccount', isPrice: true },
            { label: '充电币:', value: 'chargeCoinsAgentSubAccount' }
          ]
        },
        {
          key: '',
          label: '物业公司\n分账金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'propertyCompanySubAccount', isPrice: true },
            { label: '充电币:', value: 'chargeCoinsPMSubAccount' }
          ]
        },
        { key: 'returnAllocateNum', label: '扣回分账\n订单数', textColor: 'primary', priceText: false, isKeyValue: false, canClick: true },
        { key: 'returnAllocateMoney', label: '扣回分账\n总金额', textColor: 'primary', priceText: true, isKeyValue: false },
        {
          key: '',
          label: '扣回分账\n明细',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '服务商:', value: 'returnSpAllocateMoney', isPrice: true },
            { label: '代理商:', value: 'returnAgentAllocateMoney', isPrice: true },
            { label: '物业公司:', value: 'returnPmAllocateMoney', isPrice: true }
          ]
        }
      ],
      searchOption: {
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2),
        time: BaseSearchModel.initData('日期范围', 'time', 'DatePickerRange').setDatePickerElevator(['近7天', '近30天', '近90天', '近180天', '近365天'])
      },
      settingItems: [
        BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(12).setPermission('system:finance:settleAccount:query')
      ],
      settingExportCondition: {},
      page: { current: 1, total: 0 },
      tableData: [],
      pageData: {},
      dialog: {
        detailModel: null,
        deductBackModel: null,
        deductBackSearchOptionsData: null
      }
    };
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRequestParams () {
      const s = this.searchOption;
      return {
        provinceName: s.region.value[0] || '',
        cityName: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        timeBegin: s.time.value[0] || '',
        timeEnd: s.time.value[1] || ''
      };
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
      this.requestTotalData();
    },
    _clickSetting (key) {},
    _clickValue (key) {
      console.log(key);
      if (key === 'returnAllocateNum') {
        console.log('去扣回分账管理页面');
        this.dialog.deductBackSearchOptionsData = this.getRequestParams();
        this.dialog.deductBackModel = this.pageData;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestTotalData () {
      const api = FinanceApiSet.subAccountSummary;
      api.params = this.getRequestParams();
      this.$http(api).then(res => {
        // console.log(res);
        this.pageData = CapitalOrderTotalModel.initModelWithData(res.data);
      });
      // const resData = CapitalOrderTotalModel.createFakeData();
      // this.pageData = CapitalOrderTotalModel.initModelWithData(resData);
    },
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = FinanceApiSet.subAccountDetail;
      api.params = this.getRequestParams();
      api.params.pageNo = this.page.current;
      api.params.pageSize = this.$config.pageSize;
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        // console.log(res);
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = CapitalOrderListModel.initListWithDataList(resData.rows);
      });
      // const resList = CapitalOrderListModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = CapitalOrderListModel.initListWithDataList(resList);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
